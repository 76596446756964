/**
 * Created by BETALOS on 18/12/2015.
 */


(function () {

    'use strict';

    const FILE_MANAGER_LAYOUT_ID = 'file-manager-layout';
    const FILE_MANAGER_FILTER_DIR = 'file-manager-filter-dir';

    const WEB_CAM_DIALOG = require('shared/dialogs/web-cam-dialog');
    const AUDIO_RECORD_DIALOG = require('../dialogs/audio-record-dialog');

    mnFilesCtrl.$inject = [
        "$element", "uploadService", "$translate", "$mdDialog", "$scope", "dragulaService", "mnWebSocket", "$timeout",
        "$auth", "Upload", "system", "dcmService", "interfacingService", "CloudPlatformsService"
    ];

    function mnFilesCtrl(
        $elem, uploadService, $translate, $mdDialog, $scope, dragulaService, mnWebSocket, $timeout, $auth, Upload,
        system, dcmService, interfacingService, CloudPlatformsService
    ) {
        let vm = this;

        let files = [];
        let drake = null;
        let last_source = null;

        const bag = "manager-bag";
        const dateTimeFormat = system['datetime_format'].js;

        vm.files = [];
        vm.folderName = null;
        vm.folderLabel = $translate.instant("file_manager_new_folder");
        vm.showType = localStorage.getItem(FILE_MANAGER_LAYOUT_ID) || 'column';
        vm.filterDir = localStorage.getItem(FILE_MANAGER_FILTER_DIR) || 'desc';

        vm.$onInit = init;
        vm.uploadFiles = uploadFiles;

        vm.switchView = switchView;
        vm.addFolder = addFolder;
        vm.takePhoto = takePhoto;
        vm.dateSortList = dateSortList;

        vm.remove = remove;
        vm.openFile = openFile;
        vm.download = download;

        vm.recordAudio = recordAudio;

        vm.checkContext = checkContext;

        //importation
        vm.importDcm = importDcm;
        vm.importLastDcm = importLastDcm;
        vm.importInterfacing = importInterfacing;
        vm.importLastInterfacing = importLastInterfacing;

        // fire pacs integration related
        vm.firePacsIsActive = dcmService.integrationConfig.is_active;
        vm.importFirePacsStudy = importFirePacsStudy;
        vm.importLastFirePacsStudy = importLastFirePacsStudy;

        //dental filter
        vm.clearFilter = clearFilter;
        vm.filterItems = filterItems;
        vm.showFilterClear = showFilterClear;
        vm.importCloudPlatformReport = importCloudPlatformReport;

        vm.toggleExtraContextFilterUse = toggleExtraContextFilterUse;

        function init() {
            $elem
                .addClass("flex-noshrink")
                .addClass("layout-column");


            vm.filters = _.isNil(vm.filters) ? [] : vm.filters;
            vm.extraContext = _.isNil(vm.extraContext) ? {} : vm.extraContext;
            vm.useExtraContext = vm.useExtraContext || true;

            dragulaService.options($scope, bag, {
                copy: true,
                isContainer: el => $(el).hasClass('folder'),
                invalid: el => $(el).hasClass("empty-list") || $(el).hasClass('parent-folder'),
            });

            drake = dragulaService.find($scope, bag).drake;

            drake.on("drag", startDrag);
            drake.on("dragend", endDrag);
            drake.on("cloned", cloned);
            drake.on("over", overContainer);
            drake.on("out", outContainer);
            drake.on("drop", dropElement);
            drake.on("shadow", shadowElement);

            mnWebSocket.sub("shared.upload.FileManager.file_converted", fileConverted);
            mnWebSocket.sub("shared.upload.FileManager.folder_notify", fileUpdated);

            $scope.$on('$destroy', unSubscribe);

            function unSubscribe() {
                mnWebSocket.unsub("shared.upload.FileManager.file_converted");
                mnWebSocket.unsub("shared.upload.FileManager.folder_notify");
            }

            $scope.$watch('vm.filter', _.mnDelay(filterItems, 300), true);

            let unwatch = $scope.$watch('vm.rootId', handleRoot);

            function handleRoot(rootId) {
                if (_.isUndefined(rootId)) return;

                unwatch();

                vm.isPatient = vm.root === 'patient';
                vm.promise = uploadService.getRootFolder(vm.root, rootId, vm.context, vm.useExtraContext ? vm.extraContext : {})
                    .then(success);
            }
        }

        function startDrag(el) {
            let dataFile = $(el).data('file');
            $(`[data-file=${dataFile}]`).addClass("selected-file");
        }

        function endDrag(el) {
            let dataFile = $(el).data('file');
            $(`[data-file=${dataFile}]`).removeClass("selected-file");
        }

        function cloned(clone) {
            $(clone).removeClass("selected-file");
        }

        function overContainer(el, container, e_source) {
            let $ctn = $(container);
            if ($ctn.is('md-content')) return

            last_source = $ctn.data('file');
            if (container !== e_source) $(container).addClass("selected-folder");
        }

        function outContainer(el, container, e_source) {
            last_source = null;
            if (container !== e_source) $(container).removeClass("selected-folder");
        }

        function dropElement(el, e_target) {
            const $target = $(e_target);
            if (_.isNull(e_target) || $target.is('md-content')) return;

            $target.removeClass("selected-folder");

            const fileID = $(el).data('file');
            const folderID = $target.data('file');

            const file = _.find(vm.files, {id: fileID});
            const folder = $target.is('.parent-folder') ? vm.parentFolder : _.find(vm.files, {id: folderID});

            vm.promise = uploadService
                .addFileToFolder(folder, file, vm.folder, vm.context, vm.extraContext)
                .then(success);
        }

        function shadowElement(el) {
            $(el).remove();
        }

        function uploadFiles(files) {
            if (_.isEmpty(files)) return null;
            else if (!_.isNil(vm.preSave)) vm.preSave().then(done);
            else done();

            function done() {
                $timeout(() => {
                    vm.promise = uploadService.upload(files, vm.folder.id, vm.isPatient ? vm.rootId : null, vm.context, vm.extraContext)
                        .then(data => {
                            clearFilter();
                            publishReload(data);
                        });
                })
            }
        }

        function publishReload(data) {
            mnWebSocket.pub("shared.upload.FileManager.folder_notify", {patient_id: vm.rootId}, true);
            success(data);
        }

        function addFolder() {
            vm.promise = uploadService
                .addFolder(vm.folder, vm.folderLabel, vm.context, vm.extraContext)
                .then(success);
        }

        function takePhoto(multi, ev) {
            $mdDialog.show(_.assign({}, WEB_CAM_DIALOG, {
                targetEvent: ev,
                locals: {
                    multi, noAspect: !multi,
                }
            })).then(files => uploadFiles(multi ? files : [files]));
        }

        function success(data) {
            if (_.isNil(data)) return;

            vm.folder = data;
            vm.trash = _.remove(data.files, {is_deleted: true});

            files = data.files;

            vm.files = filter(files);

            vm.parentFolder = data['parent_folder'];

            vm.folderName = data['parent_folder'] ? data['name'] : null;
        }

        function switchView() {
            vm.showType = vm.showType === 'column' ? 'row' : 'column';
            localStorage.setItem(FILE_MANAGER_LAYOUT_ID, vm.showType);
        }

        function dateSortList() {
            vm.sortDirection = vm.sortDirection === 'desc' ? 'asc' : 'desc';

            vm.files = filter(vm.files);
            localStorage.setItem(FILE_MANAGER_FILTER_DIR, vm.sortDirection);
        }

        function filter(list) {
            return _.chain(list).compact().orderBy(item => moment(item['created_at'], dateTimeFormat).valueOf(), vm.sortDirection).value();
        }

        function remove(file) {
            vm.promise = uploadService.removeFile(file.id)
                .then(success);

            function success() {
                vm.trash = _.concat(vm.trash, _.remove(vm.files, {id: file.id}));
                mnWebSocket.pub("shared.upload.FileManager.folder_notify", {patient_id: vm.rootId}, true);
            }
        }

        function openFile(file, editImage, event) {
            if (file.mime === "folder") {
                vm.promise = uploadService.loadFolder(file.id, vm.context, vm.extraContext)
                    .then(success);
            } else if (file['is_visualized'] && file['is_converted']) {
                let visualizedFiles = editImage ? _.castArray(file) : _.filter(vm.files, {
                    is_visualized: true,
                    is_converted: true
                });

                let fileIndex = editImage ? 0 : _.findIndex(visualizedFiles, file);

                uploadService.visualizeFiles(visualizedFiles, fileIndex, event, editImage)
                    .then(uploadFiles);

            } else if (file['is_visualized'] && !file['is_converted']) {
                $mdDialog.show(notConvertedDialog(event));
            } else download(file);
        }

        function notConvertedDialog(event) {
            return $mdDialog.alert()
                .ok('OK')
                .multiple(true)
                .targetEvent(event)
                .parent(document.body)
                .clickOutsideToClose(true)
                .ariaLabel('file not yet converted')
                .title($translate.instant('file_manager_not_yet_converted'))
        }

        function download(file) {
            vm.promise = uploadService.downloadFile(file);
        }

        function recordAudio(ev) {
            $mdDialog.show(_.assign({}, AUDIO_RECORD_DIALOG, {
                targetEvent: ev,
                multiple: true,
            })).then(audio => uploadFiles([audio]));
        }

        function fileConverted(file) {
            const index = _.findIndex(vm.files, {id: file.id});

            if (index !== -1) {
                vm.files.splice(index, 1, file);
                $scope.$applyAsync();
            }
        }

        function fileUpdated(data) {
            if (vm.rootId === data['patient_id']) {
                vm.promise = uploadService.getRootFolder(vm.root, vm.rootId, vm.context, vm.useExtraContext ? vm.extraContext : {})
                    .then(success);
            }
        }

        function checkContext(file) {
            return _.isMatch(file.context, vm.context);
        }

        function importDcm(ev) {
            dcmService.importDcmInstance(vm.rootId, vm.preSave, vm.context, ev);
        }

        function importLastDcm() {
            vm.promise = dcmService.importLastDcmInstance(vm.rootId, vm.preSave, vm.context);
            return vm.promise;
        }

        function importInterfacing(ev) {
            interfacingService.importInterfacingInstance(vm.rootId, vm.preSave, vm.context, ev);
        }

        function importLastInterfacing() {
            vm.promise = interfacingService.importLastInterfacingInstance(vm.rootId, vm.preSave, vm.context);
            return vm.promise;
        }

        function clearFilter() {
            vm.filter = {};
            vm.files = filter(files);
        }

        function filterItems() {
            if (showFilterClear()) {
                vm.promise = uploadService.rootContextFiles(vm.root, vm.rootId, vm.filter)
                    .then(filterDone);
            } else vm.files = filter(files);

            function filterDone(data) {
                vm.files = data;
            }
        }

        function showFilterClear() {
            return !_.chain(vm.filter).omitBy(_.isEmpty).isEmpty().value();
        }

        // fire pacs related
        function importFirePacsStudy(ev) {
            dcmService.importFirePacsStudy(vm.rootId, vm.preSave, vm.context, ev);
        }

        function importLastFirePacsStudy() {
            vm.promise = dcmService.importLastFirePacsStudy(vm.rootId, vm.preSave, vm.context);
            return vm.promise;
        }

        function importCloudPlatformReport(ev) {
            CloudPlatformsService.importReportInstance(vm.rootId, vm.preSave, vm.context, ev);
        }

        function toggleExtraContextFilterUse() {
            vm.useExtraContext = !vm.useExtraContext;
            vm.promise = uploadService.getRootFolder(vm.root, vm.rootId, vm.context, vm.useExtraContext ? vm.extraContext : {})
                .then(success);
        }
    }

    module.exports = {
        bindings: {
            root: "@",
            rootId: "<",
            context: "<",
            preSave: "&?",
            filters: "<?",
            extraContext: "<?",
            filter: "=?contextFilter",
            useExtraContext: "=?",
        },
        controllerAs: "vm",
        controller: mnFilesCtrl,
        template: require('../views/files.html')
    };

})();
