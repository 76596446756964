/**
 * Created by amine on 02/01/2019.
 */
(function () {
    'use strict';

    class PtService {
        constructor($q, $http, mnWebSocket, $translate, $mdDialog, $state) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
            this.$state = $state;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$translate", "$mdDialog", "$state"];
        }

        getRecordsSheet(patient) {
            patient = _.isObject(patient) ? patient.id : patient

            return this.ws.call("specifics.PtRecordsSheet.get_sheet", {patient});
        }

        saveRecordsSheet(sheet) {
            const url = `/api/pt-record/${!_.isNil(sheet.id) ? sheet.id + "/" : ""}`;
            let deferred = this.$q.defer();

            this.$http.post(url, sheet)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }
    }

    module.exports = PtService;
})();