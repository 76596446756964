/**
 * Created by amine on 10/09/2018.
 */
(function () {
    'use strict';

    const {Subject} = require("rxjs");

    class ObservationService {
        constructor($q, $http, mnWebSocket, listService, examService, $translate, $mdDialog, $state) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
            this.$state = $state;
            this.listService = listService;
            this.examService = examService;

            this.currentSubscription = new Subject();
            this.newItemSubscription = new Subject();
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "listService", "examService", "$translate", "$mdDialog", "$state"];
        }

        saveObservation(observation) {
            const url = `/api/observation/${!_.isNil(observation.id) ? observation.id + "/" : ""}`;
            let deferred = this.$q.defer();

            this.$http.post(url, observation)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }

        saveEntry(entry, visitId, patientId) {
            switch (entry.type.cat) {
                case 'report':
                    return this.saveReportEntry(entry, visitId, patientId)
                default:
                    return this.saveSimpleEntry(entry);
            }
        }

        saveSimpleEntry(entry) {
            const url = `/api/observation-entry/${!_.isNil(entry.id) ? entry.id + "/" : ""}`;
            let deferred = this.$q.defer();

            this.$http.post(url, entry)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }

        saveReportEntry(entry, visitId, patientId) {
            let deferred = this.$q.defer();
            this.examService.simpleSaving({
                content: entry.content,
                exam_date: entry.entry_date,
                visit: visitId,
                template: _.pick(entry.type.special_instance, "id"),
            }, patientId).then(data => {
                const examId = data.id;
                this.ws.call("specifics.Observation.save_report_entry", _.assign(entry, {
                    report: examId
                })).then(deferred.resolve);
            }, deferred.reject);

            return deferred.promise;
        }

        saveExternalReportEntry(report, observation) {
            return this.ws.call("specifics.Observation.save_report_entry", {
                external: true,
                observation,
                report
            });
        }

        saveMedicalPrescriptionEntry(prescriptionId = null, observationId = null) {
            if (_.isNil(observationId) || _.isNil(prescriptionId)) return false;

            return this.ws.call("specifics.Observation.save_mp_entry", {
                prescription: prescriptionId,
                observation: observationId == -1 ? null : observationId
            });
        }

        updateObservation(observation) {
            const url = `/api/observation/${!_.isNil(observation.id) ? observation.id + "/" : ""}`;
            let deferred = this.$q.defer();

            this.$http.put(url, observation)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }


        get(patient) {
            return this.ws.call("specifics.Observation.get", _.isObject(patient) ? _.pick(patient, "id") : {id: patient});
        }

        getObservation(id) {
            let deferred = this.$q.defer();

            this.$http.get(`/api/observation/${id}/`)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }

        deleteEntry(entry) {
            const url = `/api/observation-entry/${entry.id}/`;
            let deferred = this.$q.defer();

            this.$http.delete(url, entry)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }

        editEntryTypes(item, $event) {
            let deferred = this.$q.defer();

            this.$mdDialog.show(_.assign(require('shared/dialogs/list-dialog'), {
                template: require("shared/views/list.default.tpl.html"),
                targetEvent: $event,
                locals: {
                    list: item,
                    mnLabel: "observation_entry_new_type",
                    mnModel: "ObservationEntryType"
                },
            })).then(data => {
                deferred.resolve(data);
            }, deferred.reject);

            return deferred.promise;
        }

        editReportEntryTypes(item, $event) {
            let deferred = this.$q.defer();

            this.$mdDialog.show(_.assign(require('shared/dialogs/report-as-type-dialog'), {
                targetEvent: $event,
                locals: {
                    item: item
                },
            })).then(data => {
                deferred.resolve(data);
            }, deferred.reject);

            return deferred.promise;
        }

        editIndication(item, $event) {
            let deferred = this.$q.defer();

            this.$mdDialog.show(_.assign(require('shared/dialogs/list-dialog'), {
                template: require("shared/views/list.default.tpl.html"),
                targetEvent: $event,
                locals: {
                    list: item,
                    mnLabel: "observation_entry_new_indication",
                    mnModel: "ObservationIndication"
                },
            })).then(data => {
                deferred.resolve(data);
            }, deferred.reject);

            return deferred.promise;
        }

        observationSummary(patient) {
            return this.ws.call('specifics.Observation.retrieve_summary', {patient});
        }

        deleteObservation(item) {
            const url = `/api/observation/${item.id}/`;
            let deferred = this.$q.defer();

            this.$http.delete(url, item)
                .then(result => {
                    deferred.resolve(result.data);
                }, result => {
                    deferred.reject(result.data);
                });

            return deferred.promise;
        }

        returnTo(id = null) {
            return this.$state.go("app.visit.consultation.observation", {
                // 'is-last': true,
                'read-only': false,
                'entry': null,
                'observation': id == -1 ? null : id
            });
        }

        medicalPrescriptionState(prescriptionId = null, observationId = -1, copy = null) {
            const url = `app.visit.prescription.medical-prescription`;

            return this.$state.go(url, {
                copy: copy,
                prescriptionId: prescriptionId,
                observationId: observationId,
            }, {inherit: true});
        }

        reportFormState(entryType, examId = null, observationId = -1, model = null, copy = null) {
            if (!entryType.special_instance) return false;
            const url = `app.visit.exam.form`;


            return this.$state.go(url, {
                template: entryType.special_instance.id,
                examId: examId,
                model: null,
                copy: copy,
                observationId: observationId,
            }, {inherit: true});
        }
    }

    module.exports = ObservationService;
})();
