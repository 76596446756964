/**
 * Created by BETALOS on 23/06/2016.
 */
(function () {
    'use strict';

    class HtmlBlockController {
        constructor(blockService, $element, $compile, $scope) {
            this.$scope = $scope;
            this.$element = $element;
            this.$compile = $compile;
            this.blockService = blockService;

            this.key = null;
            this.patient = null;
        }

        static get $inject() {
            return ["blockService", "$element", "$compile", "$scope"];
        }

        $onInit() {
            const version = _.get(this.model, 'version', null);

            this.readOnly = _.isUndefined(this.readOnly) ? false : this.readOnly();
            this.hasHeader = _.isUndefined(this.hasHeader) ? true : this.hasHeader();
            this.ngModelController = this.ngModelController ? this.ngModelController : null;

            if (this.hasHeader) this.$element.addClass("flex-noshrink layout-column");

            this.editorOpts = {
                "auto_compile": true,
                "no_config_key": true,
                "has_misc_menu": true,
                "patient": this.patient,
                "has_patient_menu": true,
                "has_physician_menu": true,
                "has_measurement_menu": true,
            };

            this.blockService.get(this.key, version)
                .then(data => this.success(data));
        }

        success(data) {
            this.model = _.isUndefined(this.model) ? {} : this.model;

            this.title = data.title;
            this.secondTitle = data.second_title;
            this.hasSecondTitle = data.has_second_title;
            this.model['version'] = data.id;

            this.meanings = data['meanings'];
            this.questions = data['questions'];
            this.html_fields = data['html_fields'];

            this.handleTemplate(data.type);

            if (this.html_fields && this.html_fields.length === 1 && this.html_fields[0].type === "text-editor" && _.get(this.html_fields, "0.meta_data.fill_layout", false)) {
                this.$element.addClass("layout-fill");
                this.$element.parent("mn-consultation-body").addClass("layout-fill");
            }
        }

        handleTemplate(type) {
            let tpl = require(`stand-alone/blocks/views/${type === "html_block" ? 'html' : 'survey'}-block.tpl.html`);

            let childScope = this.$scope.$new();
            let compiledDirective = this.$compile(tpl);
            let directiveElement = compiledDirective(childScope);

            this.$element.html(directiveElement);
        }

        autoSaveCall() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }
    }

    module.exports = {
        template: '',
        bindings: {
            hasHeader: "&?",
            model: "=ngModel",
            key: "@mnBlockKey",
            patient: "<patient",
            title: "=?itemTitle",
            readOnly: "&?mnReadOnly",
            postSaveSubject: "<?postSaveSubject",
        },
        controllerAs: "vm",
        controller: HtmlBlockController,
        require: {ngModelController: "ngModel"},
    };

})();
