/**
 * Created by BETALOS on 05/11/2016.
 */
(function () {

    'use strict';

    class SurveyResumeCtrl {
        constructor($scope, $element) {
            this.scope = $scope;

            $element.addClass("flex layout-row");
        }

        static get $inject() {
            return ["$scope", "$element"];
        }

        $onInit() {
            this.scope.$watch('vm.model', () => this.changeTotal(), true);
        }

        changeTotal() {
            this.total = this.calculateTotal();
            this.totalRange = this.calculateTotalRange();
        }

        calculateTotal() {
            if (_.isNil(this.model)) return 0;
            else return _.chain(this.model).omit('version').map('score').sum().value();
        }

        calculateTotalRange() {
            if (_.isNil(this.meanings)) return;

            const range = _.find(this.meanings, i => this.total > i['range_from'] && this.total <= i['range_to']);

            return _.isUndefined(range) ? {} : {color: range.color};
        }

    }

    module.exports = {
        bindings: {
            model: '=',
            meanings: '=',
        },
        controllerAs: "vm",
        controller: SurveyResumeCtrl,
        template: require('stand-alone/blocks/views/survey-resume.tpl.html')
    };


})();