/**
 * Created by amine on 12/04/2018.
 */
(function () {
    'use strict';

    module.exports = vaccinationService;

    vaccinationService.$inject = ["$q", "$http", "mnWebSocket"];

    function vaccinationService($q, $http, mnWebSocket) {
        let self = this;

        self.getVaccines = getVaccines;
        self.saveVaccine = saveVaccine;
        self.removeVaccine = removeVaccine;
        self.vaccinesList = vaccinesList;
        self.saveVaccinationConfig = saveVaccinationConfig;
        self.calendarConfigsList = calendarConfigsList;
        self.removeVaccinationConfig = removeVaccinationConfig;
        self.getCalendar = getCalendar;
        self.saveCalendar = saveCalendar;
        self.editCalendarLine = editCalendarLine;

        function vaccinesList(query) {
            return mnWebSocket.call("specifics.Vaccination.vaccines", query);
        }

        function calendarConfigsList(query) {
            return mnWebSocket.call("specifics.Vaccination.configs", query);
        }

        function getVaccines() {
            let deferred = $q.defer();
            let url = "/api/vaccine-unit/";

            $http.get(url).then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(result) {
                deferred.reject(result.data);
            }

            return deferred.promise;
        }

        function saveVaccine(vaccine) {
            let deferred = $q.defer();
            let url = "/api/vaccine-unit/" + (!_.isNil(vaccine.id) ? vaccine.id + "/" : "");

            $http.post(url, vaccine)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(result) {
                deferred.reject(result.data);
            }

            return deferred.promise;
        }

        function removeVaccine(vaccine) {
            return $http.delete("/api/vaccine-unit/" + vaccine.id + '/');
        }

        function saveVaccinationConfig(vaccination) {
            const deferred = $q.defer();
            const url = "/api/vaccination-calendar/" + (!_.isNil(vaccination.id) ? vaccination.id + "/" : "");

            $http.post(url, vaccination)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(result) {
                deferred.reject(result.data);
            }

            return deferred.promise;
        }

        function removeVaccinationConfig(config) {
            const url = "/api/vaccination-calendar/" + config.id + '/';
            return $http.delete(url);
        }

        function getCalendar(patient) {
            return mnWebSocket.call("specifics.Vaccination.calendar", {patient: patient});
        }

        function saveCalendar(calendar) {
            return mnWebSocket.call("specifics.Vaccination.save", calendar);
        }

        function editCalendarLine(line){
            return mnWebSocket.call("specifics.Vaccination.save_line", line);
        }
    }

})();
