/**
 * Created by BETALOS on 05/11/2016.
 */
(function () {

    'use strict';

    class SurveyQuestionCtrl {
        constructor($element) {
            $element.addClass("layout-column");
        }

        static get $inject() {
            return ["$element"];
        }

        $onInit() {
            this.answerChanged = _.isFunction(this.answerChanged) ? this.answerChanged : _.noop;
        }

        selectAnswer(answer) {
            this.ngModelCtrl.$setViewValue(answer);
            this.ngModelCtrl.$render();
        }

    }

    module.exports=  {
        bindings: {
            disabled: '<',
            question: "<",
            answer: "=ngModel",
            answerChanged: '<',
        },
        require: {
            ngModelCtrl: 'ngModel'
        },
        controllerAs: "vm",
        controller: SurveyQuestionCtrl,
        template: require('stand-alone/blocks/views/survey-question.tpl.html'), // or template
    };

})();