/**
 * Created by amine on 02/01/2019.
 */
(function () {
    'use strict';

    class PathologyService {
        constructor($q, $http, mnWebSocket, $translate, $mdDialog) {
            this.$q = $q;
            this.$http = $http;
            this.ws = mnWebSocket;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$translate", "$mdDialog"];
        }

        getPathologies() {
            let deferred = this.$q.defer();
            const url = `/api/pathology-list/`;

            this.$http.get(url).then(
                result => deferred.resolve(result.data), deferred.reject
            );

            return deferred.promise;
        }

        getPathology(id) {
            let deferred = this.$q.defer();
            const url = `/api/pathology-list/${id}/`;

            this.$http.get(url).then(result => {
                deferred.resolve(result.data);
            }, result => {
                deferred.resolve(result.data);
            });

            return deferred.promise;
        }

        savePathology(model) {
            let deferred = this.$q.defer();
            const url = `/api/pathology-list/${_.isNil(model.id) ? "" : `${model.id}/`}`;

            this.$http.post(url, model).then(result => {
                deferred.resolve(result.data);
            }, result => {
                deferred.resolve(result.data);
            });

            return deferred.promise;
        }

        removePathology(model){
            const url = `/api/pathology-list/${!_.isNil(model.id) ? `${model.id}/` : ""}`;
            let deferred = this.$q.defer();

            this.$http.delete(url).then(result => {
                deferred.resolve(result.data);
            }, result => {
                deferred.resolve(result.data);
            });

            return deferred.promise;
        }
    }

    module.exports = PathologyService;
})();